<template>
  <div class="articles-list">
    <Banner>
      <div class="banner-content">
        <MeepIconNews class="app-icon-stroke" />
        <span class="banner-content__text">{{ $t("menu.news") }}</span>
      </div>
    </Banner>
    <PageHeader
      v-model="query"
      :has-actions="false"
      :has-back="true"
      :has-search="true"
    />
    <div class="articles-list-content page-layout">
      <LoadingCard v-if="isLoading" />

      <sortable-list
        v-if="!isLoading"
        :has-pagination="true"
        :is-card="false"
        :items="filter"
        :parameters="listParameters"
        class="page-list"
        default-sort="created"
        default-sort-order="desc"
        link="/dashboard/news/"
      />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Fuse from "fuse.js";
import news from "@/model/news";
import LoadingCard from "@/components/LoadingCard";
import Banner from "@/components/Banner";
import MeepIconNews from "@/components/icons/MeepIconNews.vue";

export default {
  name: "ListNews",

  components: {
    LoadingCard,
    PageHeader,
    Banner,
    MeepIconNews,
  },

  data() {
    return {
      isLoading: false,
      articles: [],
      query: "",

      listParameters: [
        {
          name: "",
          key: "img",
          isImage: true,
        },
        {
          name: "Titre",
          key: "title",
        },
        {
          name: "Créée le",
          key: "created",
          format: this.$$filters.formatDate,
        },
      ],
    };
  },

  computed: {
    filter() {
      const articles = this.articles;

      if (this.query === "") {
        return articles;
      }

      const fuse = new Fuse(articles, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        keys: ["title"],
      });

      return fuse.search(this.query).map(item => item.item);
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      const articles = await news.getActualites({
        page: 0,
        limit: 100,
      });

      this.articles = articles.map(article => {
        article.id = article.nid;

        this.setNewsDataUrl(article);
        return article;
      });

      this.isLoading = false;

      this.$scrollTo(".articles-list");
    } catch (err) {
      this.articles = [];

      this.$toasted.global.AppError({
        message: err.msg,
      });
      this.isLoading = false;
    }
  },

  methods: {
    setNewsDataUrl(news) {
      news.img =
        news.fields && news.fields.field_image.und
          ? `https://expertplus.expertsa.net/sites/default/files/${news.fields.field_image.und[0].filename}`
          : "assets/images/default-image.jpg";
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app .articles-list {
  .banner {
    .app-icon-stroke {
      width: 20px;
      height: 20px;
      @include for-lg {
        width: 30px;
        height: 30px;
      }

      path {
        stroke: var(--text-primary);
      }
    }
  }

  &-content {
    &.page-layout {
      height: 100%;

    }
  }

  .page-header {
    padding: 0 toRem(25) toRem(21) toRem(29);
    @include for-lg {
      padding: 0 toRem(25) toRem(30) toRem(29);
    }
  }

  .sortable-list {
    padding: 0;
  }
}
</style>
